<template>
  <div>
    <Nav />
    <Main />
    <Sections />
    <Function />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import Main from "@/components/Main";
import Sections from "@/components/Sections";
import Function from "@/components/Function";
import Footer from "@/components/Footer";

import 'bootstrap/dist/css/bootstrap.css'

export default {
  name: 'AppHome',
  components: {
    Nav,
    Main,
    Sections,
    Function,
    Footer,
  },
  methods : {
  }
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
</style>
