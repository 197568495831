<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <a
      class="navbar-brand"
      href="https://iquranapp.com"
    ><img
      src="../assets/logo.png"
      alt=""
    ></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <div
      id="navbarNav"
      class="collapse navbar-collapse justify-content-end"
    >
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link
            class="nav-link"
            to="#sections"
          >
            Разделы
          </router-link>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mob-border"
            href="#functions"
          >Функций</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mr-0"
            href="#contacts"
          >Контакты</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AppNav',
  props: {}
}
</script>

<style scoped lang="scss">
  .navbar-toggler {
    border: none;
    padding: 0 1.5rem;
    .navbar-toggler-icon {
      background-image: url("../assets/toggle.png");
    }
  }
  .navbar-toggler[aria-expanded="true"] {
    .navbar-toggler-icon {
      background-image: url("../assets/close-toggle.png");
    }
  }
  .navbar {
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: #373F47;
    .navbar-brand {
      img {
        max-height: 48px;
      }
    }
    .nav-link {
      color: #FFF!important;
      font-family: Montserrat,sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      padding-top: .8rem;
      margin-right: 2rem;
      &:hover {
        text-decoration: underline;
        color: #91E0DB!important;
        transition: .3s;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .navbar {
      padding: .5rem 8.33vw;
    }
  }
  @media screen and (max-width: 992px) {
    .navbar-brand {
      padding: .5rem 1.5rem;
    }
    .navbar {
      padding: 0;
    }
    .navbar-nav {
      flex-direction: row;
      background-color: #FFFFFF;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      .nav-link {
        color: #000!important;
        margin-right: 0;
        text-align: center;
        padding: 1.5rem;
      }
      .mob-border {
        border: 1px solid rgba(220, 216, 216, 0.43);
        border-bottom: 0;
        border-top: 0;
      }
      .nav-item {
        width: (100%/3);
      }
    }
  }
</style>
