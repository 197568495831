<template>
  <div class="main-container pb-0 col-12 d-flex align-items-center position-relative">
    <div class="col-lg-7 col-sm-12 px-0 d-flex flex-column justify-content-start main-text-container">
      <h1 class="main-title mb-0">Революционное приложение для изучения Корана</h1>
      <p class="main-text mb-0">Загрузите приложение IQuran и получите доступ к огромной базе знаний о священном писании Коран.</p>
      <div class="main-stores d-flex align-items-center">
        <a href="https://www.google.com/"><img src="../assets/play-market.png" alt=""></a>
        <a href="https://www.google.com/"><img src="../assets/app-store.png" alt=""></a>
      </div>
    </div>
    <div class="col-lg-5 col-sm-12 px-0 d-flex main-phone-container">
      <img src="../assets/main-phone.png" alt="">
    </div>
    <img class="position-absolute mob-back" src="../assets/main-phone-mob-back.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'AppMain',
  props: {}
}
</script>

<style scoped lang="scss">
.main-container {
  background-image: url("../assets/main-phone-back.png");
  background-repeat: no-repeat;
  background-position: 96.5%;
  
  .mob-back {
    display: none;
  }
  @media screen and (max-width: 992px) {
    background-image: none;
    .mob-back {
      display: block;
      bottom: -8.5%;
      width: 100% !important;
      z-index: -2;
    }
  }
}
@media screen and (min-width: 992px) {
  .main-container {
    padding: calc(1.815rem + 64px) 8.33vw 1.815rem 8.33vw;
  }
  .main-phone-container {
    justify-content: flex-end!important;
  }
}
@media screen and (max-width: 992px) {
  .main-container {
    flex-direction: column;
    padding-top: calc(40px + 64px);
    padding-bottom: 1rem;
    .main-text-container {
      margin-bottom: 4rem;
    }
    .main-title {
      width: 100%!important;
      font-size: 31px!important;
      text-align: center;
      padding: 0 2rem;
    }
    .main-text {
      width: 100%!important;
      font-size: 16px!important;
      text-align: center;
      padding: 1.5rem 0!important;
    }
    .main-stores {
      justify-content: space-around;
      img:first-child {
        margin-right: 0!important;
      }
      img {
        width: 50%;
        max-width: 146px!important;
      }
    }
    .main-phone-container {
      img {
        max-width: 206px!important;
      }
    }
  }
}
.main-container {
  .main-title {
    width: 80%;
    font-family: Merriweather,sans-serif;
    font-weight: 900;
    font-size: 3.25rem;
  }
  .main-text {
    padding: 3.25rem 0;
    width: 72%;
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3vw;
  }
  .main-stores {
    img {
      width: 100%;
      max-width: 196px;
    }
    img:first-child {
      margin-right: 2.08vw;
    }
  }
  .main-phone-container {
    justify-content: center;
    img {
      width: 100%;
      max-width: 320px;
      height: 100%;
    }
  }
}
</style>
