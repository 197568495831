<template>
  <div>
    <SimpleNav />
    <PrivacyPolicy />
  </div>
</template>

<script>
import SimpleNav from "@/components/SimpleNav";
import PrivacyPolicy from "@/components/PrivacyPolicy";

import 'bootstrap/dist/css/bootstrap.css';

export default {
  name: 'PrivacyPolicyPage',
  components: {
    SimpleNav,
    PrivacyPolicy
  },
  methods : {
  }
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
</style>
