<template>
  <div class="function-container col-12 d-flex align-items-center flex-column" id="functions">
    <div class="function-container-text d-flex flex-column align-items-center">
      <h1 class="function-title mb-0 text-center pb-3">Чтение Корана</h1>
      <p class="function-text mb-0 text-center">
        Инновационный вариант системы для ознакомления и ежедневного использования электронной версии Священного писания Коран, с нашим уникальным функционалом.
      </p>
    </div>
    <div class="function-phones-wrap d-flex row">
      <div class="d-flex flex-column align-items-center px-0 col-lg-6 col-xl-3 function-phone-card-container">
        <img src="../assets/function-phone-1.png" alt="">
        <div class="function-phones-text text-center d-flex flex-column align-items-center">
          <p class="function-phones-title mb-2 mt-3"><span class="function-phones-title-red-span">Подсвечи</span>вание текста</p>
          <p class="function-phones-text mb-0">Наблюдайте за текстом по ходу его озвучивания для максимального погружения.</p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center px-0 col-lg-6 col-xl-3 function-phone-card-container">
        <img src="../assets/function-phone-2.png" alt="">
        <div class="function-phones-text text-center d-flex flex-column align-items-center">
          <p class="function-phones-title mb-2 mt-3">Переводы</p>
          <p class="function-phones-text mb-0">Удобный моментальный доступ к переводу слова.</p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center px-0 col-lg-6 col-xl-3 function-phone-card-container">
        <img src="../assets/function-phone-3.png" alt="">
        <div class="function-phones-text text-center d-flex flex-column align-items-center">
          <p class="function-phones-title mb-2 mt-3">Офлайн доступ</p>
          <p class="function-phones-text mb-0">Возможность продолжить чтение даже без подключения к Wi-Fi.</p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center px-0 col-lg-6 col-xl-3 function-phone-card-container">
        <img src="../assets/function-phone-4.png" alt="">
        <div class="function-phones-text text-center d-flex flex-column align-items-center">
          <p class="function-phones-title mb-2 mt-3">Полноэкранный режим</p>
          <p class="function-phones-text mb-0">Несколько режимов чтения позволит достичь максмально комфортного чтения.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFunction',
  props: {}
}
</script>

<style scoped lang="scss">
.function-container {
  padding: 0 8.33vw;
  .function-title {
    font-family: Merriweather;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
  }
  .function-text {
    width: 45%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #595959;
    @media screen and (min-width: 1023px) and (max-width: 1250px) {
      width: 100% !important;
    }
  }
  .function-phones-wrap {
    width: 100%;
    padding-top: 3.25rem;
  }
  .function-phone-card-container {
    img {
      width: 100%;
      max-width: 256px;
      height: 100%;
      max-height: 520px;
    }
  }
  .function-phones-title {
    font-family: Montserrat,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    .function-phones-title-red-span {
      color: #FF0000;
    }
  }
  .function-phones-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #373F47;
    width: 90%;
  }
}
@media screen and (max-width: 992px) {
  .function-container {
    padding: 0 15px!important;
    .function-title {
      font-size: 26px;
    }
    .function-text {
      width: 100%!important;
    }
    .function-phones-wrap {
      padding-top: 3rem!important;
      .function-phones-title {
        font-size: 20px;
      }
      .function-phones-text {
        width: 100%;
      }
      .function-phone-card-container {
        padding-top: 2.5rem;

        &:first-child {
          padding-top: 0rem!important;
        }
      }
    }
  }
}
</style>
