<!--suppress ALL -->
<template>
  <div class="section-container">
    <div
      id="sections"
      class="col-12 d-flex align-items-center flex-column"
    >
      <div class="section-text-container d-flex flex-column align-items-center">
        <h1 class="section-title mb-0 text-center pb-3">
          Разделы приложения
        </h1>
        <p class="section-text mb-0 text-center">
          В нашем приложении собраны все нужные инструменты для комфортного изучения  священной книги Коран.
        </p>
      </div>
      <div class="d-flex align-items-center row phone-cards-wrap">
        <div class="phone-wrap col-md-3 col-12 phone-card-container">
          <img
            class="phone-img"
            src="../assets/section-phone.png"
            alt=""
          >
        </div>
        <div class="col-md-1 col-0" />
        <div class="cards-container col-lg-8 col-12 row">
          <div class="d-flex col-md-6 col-12">
            <div class="section-card d-flex">
              <img
                src="../assets/card_1.png"
                alt=""
              >
              <p>Раздел Коран представляет собой электронную версию Священной книги.</p>
            </div>
          </div>

          <div class="d-flex col-md-6 col-12">
            <div class="section-card d-flex">
              <img
                src="../assets/card_2.png"
                alt=""
              >
              <p>В разделе Темы Корана находится, рассортированная по категориям версия Священного писания.</p>
            </div>
          </div>

          <div class="d-flex col-md-6 col-12">
            <div class="section-card d-flex">
              <img
                src="../assets/card_3.png"
                alt=""
              >
              <p>В разделе Имена Аллаха вы можете ознакомиться с  именами Всевышнего и узнать их толкование.</p>
            </div>
          </div>

          <div class="d-flex col-md-6 col-12">
            <div class="section-card d-flex">
              <img
                src="../assets/card_4.png"
                alt=""
              >
              <p>В раделе Тафсиры вы можете ознакомиться с толкованиями и комментариями к текстам Священного писания.</p>
            </div>
          </div>

          <div class="d-flex col-md-6 col-12">
            <div class="section-card d-flex">
              <img
                src="../assets/card_5.png"
                alt=""
              >
              <p>В разделе Дуа собран полный список молитв на самые разные случаи жизни для обращения к Всевышнему.</p>
            </div>
          </div>

          <div class="d-flex col-md-6 col-12">
            <div class="section-card d-flex">
              <img
                src="../assets/card_6.png"
                alt=""
              >
              <p>Электронная версия книги о правилах орфоэпического чтения Корана и соответствующей коранической дисциплине.</p>
            </div>
          </div>

          <div class="d-flex col-md-6 col-12">
            <div class="section-card d-flex">
              <img
                src="../assets/card_7.png"
                alt=""
              >
              <p>В разделе Азан вы увидите  расписание для совершения молитв, в какой бы точке мира вы не находились.</p>
            </div>
          </div>
          <div class="d-flex col-md-6 col-12">
            <div class="section-card d-flex">
              <img
                src="../assets/card_8.png"
                alt=""
              >
              <p>В разделе Избранное находятся избранные пользователем Суры, Тафсиры и Дуа.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <swiper
      :slides-per-view="1"
      :space-between="10"
      navigation
      :pagination="{ clickable: false }"
      class="swiper-mob"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide class="">
        <div class="col-12 px-0 d-flex flex-column">
          <div class="section-card d-flex">
            <img
              src="../assets/card_1.png"
              alt=""
            >
            <p>Раздел Коран представляет собой электронную версию Священной книги.</p>
          </div>
          <div class="section-card d-flex">
            <img
              src="../assets/card_2.png"
              alt=""
            >
            <p>В разделе Темы Корана находится, рассортированная по категориям версия Священного писания.</p>
          </div>
          <div class="section-card d-flex">
            <img
              src="../assets/card_3.png"
              alt=""
            >
            <p>В разделе Имена Аллаха вы можете ознакомиться с  именами Всевышнего и узнать их толкование.</p>
          </div>
          <div class="section-card d-flex">
            <img
              src="../assets/card_4.png"
              alt=""
            >
            <p>В раделе Тафсиры вы можете ознакомиться с толкованиями и комментариями к текстам Священного писания.</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="">
        <div class="col-12 px-0 d-flex flex-column">
          <div class="section-card d-flex">
            <img
              src="../assets/card_5.png"
              alt=""
            >
            <p>В разделе Дуа собран полный список молитв на самые разные случаи жизни для обращения к Всевышнему.</p>
          </div>
          <div class="section-card d-flex">
            <img
              src="../assets/card_6.png"
              alt=""
            >
            <p>Электронная версия книги о правилах орфоэпического чтения Корана и соответствующей коранической дисциплине.</p>
          </div>
          <div class="section-card d-flex">
            <img
              src="../assets/card_7.png"
              alt=""
            >
            <p>В разделе Азан вы увидите  расписание для совершения молитв, в какой бы точке мира вы не находились.</p>
          </div>
          <div class="section-card d-flex">
            <img
              src="../assets/card_8.png"
              alt=""
            >
            <p>В разделе Избранное находятся избранные пользователем Суры, Тафсиры и Дуа.</p>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-bullets">
        <span
          class="swiper-pagination-bullet"
          :class="firstActiveClass ? 'swiper-pagination-bullet-active' : ''"
        />
        <span
          class="swiper-pagination-bullet"
          :class="secondActiveCLas ? 'swiper-pagination-bullet-active' : ''"
        />
      </div>
    </swiper>
  </div>
</template>

<script>
// import Swiper core and required modules
import SwiperCore, { A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper modules
SwiperCore.use([A11y]);

export default {
  name: 'AppSections',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {},
  data: function () {
    return {
      firstActiveClass : false,
      secondActiveCLas : false,
    }
  },
  methods: {
    onSwiper(swiper) {
      if(swiper.activeIndex == 0) {
        this.firstActiveClass = true;
        this.secondActiveCLas = false;
      } else {
        this.secondActiveCLas = true;
        this.firstActiveClass = false;
      }
    },
    onSlideChange(swiper) {
      if(swiper.activeIndex == 0) {
        this.firstActiveClass = true;
        this.secondActiveCLas = false;
      } else {
        this.secondActiveCLas = true;
        this.firstActiveClass = false;
      }
    },
  },
}
</script>

<style scoped lang="scss">
.swiper-container {
  .swiper-pagination-bullets {
    bottom: -5px;
    left: 0;
    width: 100%;
  }
  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    .swiper-pagination-bullet {
      width: 10px!important;
      height: 10px!important;
      opacity: 1;
      background: #DCD8D8;
      margin: 0 5px;
      display: inline-block;
      border-radius: 50%;
    }
    .swiper-pagination-bullet-active {
      background: #373F47;
    }
  }
}
@media screen and (max-width: 992px) {
  .section-container {
    padding: 5rem 15px!important;
  }
  .section-title {
    font-size: 26px!important;
  }
  .section-text {
    width: 100%!important;
  }
  .phone-wrap {
    display: none;
  }
  .phone-cards-wrap {
    width: 100%;
    justify-content: center;
  }
  .cards-container {
    padding-left: 0!important;
    padding-right: 0!important;
    >div {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
}
  .section-container {
    padding: 7rem 8.33vw;
    .section-title {
      font-family: Merriweather;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
    }
    .section-text {
      width: 60%;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #595959;
    }
    .section-text-container {
      padding-bottom: 3.25rem;
    }
  }

  .section-card {
    margin-bottom: 2rem;
    @media screen and (max-width: 768px) {
      &:last-child {
        margin-bottom: 2.5rem;
      }
    }
    img {
      height: 4.5rem;
      width: 4.5rem;
      margin-right: 1rem;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 150%;
      color: #000000;
      margin-bottom: 0;
    }
  }
  .swiper-mob {
    @media screen and (min-width: 768px){
      display: none;
    }
    @media screen and (max-width: 768px){
      display: block;
    }
  }
  .phone-cards-wrap {
    @media screen and (max-width: 767px){
      display: none!important;
    }
  }

  .phone-card-container {
    img {
      width: 100%;
      max-width: 270px;
      height: 100%;
      max-height: 548px;
    }
  }
</style>
