import * as VueRouter from 'vue-router';
import Home from "@/pages/index.vue";
import Privacy from "@/pages/privacy_policy.vue";

const routes = [
    { path: '/', component: Home },
    { path: '/privacy_policy', component: Privacy },
    { path: '/suras/:id', redirect: () => '/' },
    { path: '/suras/:id/:ayat_id', redirect: '/' }
]
  
const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(process.env.BASE_URL),
    routes,
})

export default router