<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <a
      class="navbar-brand"
      href="https://iquranapp.com"
    ><img
      src="../assets/logo.png"
      alt=""
    ></a>
  </nav>
</template>

<script>
export default {
  name: 'SimpleNav',
  props: {}
}
</script>

<style scoped lang="scss">
  .navbar {
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: #373F47;
    .navbar-brand {
      img {
        max-height: 48px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .navbar {
      padding: .5rem 8.33vw;
    }
  }
  @media screen and (max-width: 992px) {
    .navbar-brand {
      padding: .5rem 1.5rem;
    }
    .navbar {
      padding: 0;
    }
    .navbar-nav {
      flex-direction: row;
      background-color: #FFFFFF;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      .nav-link {
        color: #000!important;
        margin-right: 0;
        text-align: center;
        padding: 1.5rem;
      }
      .mob-border {
        border: 1px solid rgba(220, 216, 216, 0.43);
        border-bottom: 0;
        border-top: 0;
      }
      .nav-item {
        width: (100%/3);
      }
    }
  }
</style>
