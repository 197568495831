<template>
  <div class="footer-container d-flex align-items-center flex-column" id="contacts">
    <div class="footer-main-container d-flex">
      <div class="footer-logo-container footer-main-desc-container">
        <div>
          <a href="#nav"><img src="../assets/footer-logo.png" alt=""></a>
          <p class="footer-logo-container-text pt-4 mb-0">Революционное приложение для <br> изучения Священной книги Коран.</p>
        </div>
      </div>
      <div class="footer-links-container footer-main-desc-container">
        <div>
          <h3 class="footer-links-title mb-0">Ссылки</h3>
          <ul class="footer-ul-links mb-0 pt-4">
            <li class="footer-link-li pl-1 pb-3"><a href="#main">Скачать</a></li>
            <li class="footer-link-li pl-1 pb-3"><a href="#sections">Разделы приложения</a></li>
            <li class="footer-link-li pl-1 pb-3"><a href="#functions">Чтение Корана</a></li>
            <li class="footer-link-li pl-1 pb-3"><a href="/privacy_policy">Политика конфиденциальности</a></li>
            <li class="footer-link-li pl-1 pb-3"><a href="/terms_of_use.html">Пользовательское соглашение</a></li>
          </ul>
        </div>
      </div>
      <div class="footer-contacts-container footer-main-desc-container">
        <div>
          <h3 class="footer-contacts-title mb-0">Контакты</h3>
          <div class="d-flex pt-4 footer-contacts-column-container">
            <div class="footer-mail-link-container d-flex align-items-center">
              <img class="pr-1" src="../assets/contact-mail.png" alt="">
              <a href="mailto:info@iquranapp.com">info@iquranapp.com</a>
            </div>
            <div class="d-flex align-items-center footer-social-link">
              <a href="#" class="pr-3"><img src="../assets/footer-facebook.png" alt=""></a>
              <a href="#" class="pr-3"><img src="../assets/footer-instagram.png" alt=""></a>
              <a href="#" class="pr-3"><img src="../assets/footer-vk.png" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-rights-container position-relative">
      <hr class="position-absolute footer-rights-line m-0">
      <p class="text-center text-light footer-rights-text mb-0">©2021 iQuran. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  props: {}
}
</script>

<style scoped lang="scss">
.footer-container {
  margin-top: 7.75rem;
  padding: 0 8.33vw;
  background-color: #373F47;
  background-image: url("../assets/footer-back.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  @media screen and (max-width: 992px) {
    margin-top: 5rem;
    background-image: url("../assets/footer-mob-back.png");
  }
  .footer-main-container {
    width: 100%;
    padding: 3rem 0;
    @media screen and (max-width: 992px) {
      padding: 2rem 0!important;
      flex-direction: column!important;
    }
    .footer-main-desc-container {
      width: (100%/3);
      justify-content: center;
      display: flex;
      @media screen and (max-width: 992px) {
        justify-content: flex-start;
        width: 100% !important;
      }
    }
    .footer-logo-container {
      img {
        max-width: 157px;
        width: 100%;
        max-height: 56px;
        height: 100%;
      }
      @media screen and (max-width: 992px) {
        padding-bottom: 2rem;
      }
      .footer-logo-container-text {
        font-family: Montserrat,sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #FFFFFF;
        @media screen and (max-width: 992px) {
          width: 100% !important;
        }
        @media screen and (max-width: 321px) {
          width: 80% !important;
        }
      }
    }
    .footer-links-container {
      @media screen and (max-width: 992px) {
        padding-bottom: 2rem;
      }
      .footer-links-title {
        font-family: Merriweather,sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #FFFFFF;
        @media screen and (max-width: 992px) {
          font-size: 24px;
        }
      }
      .footer-ul-links {
        padding-left: 1rem;
        .footer-link-li {
          list-style-image: url("../assets/li-arrow.png");
          a {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #FFFFFF;
            &:hover {
              color: #91E0DB!important;
              transition: .3s;
            }
          }
        }
      }
    }
    .footer-contacts-container {
      .footer-contacts-title {
        font-family: Merriweather;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #FFFFFF;
        @media screen and (max-width: 992px) {
          font-size: 24px;
        }
      }
      .footer-contacts-column-container {
        flex-direction: column;
        @media screen and (max-width: 992px) {
          flex-direction: column-reverse!important;
        }
      }
      .footer-social-link {
        padding-top: 1.5rem;
        img {
          width: 100%;
          height: 100%;
          max-width: 27px;
          max-height: 27px;
        }
        @media screen and (max-width: 992px) {
          padding-top: 0!important;
          padding-bottom: 1.5rem;
        }
      }
      .footer-mail-link-container {
        img {
          width: 100%;
          height: 100%;
          max-width: 18px;
          max-height: 18px;
        }
        a {
          font-family: Montserrat,sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #FFFFFF;
          &:hover {
            color: #91E0DB!important;
            transition: .3s;
          }
        }
      }

    }
  }
  .footer-rights-container {
    width: 100%;
    .footer-rights-line {
      top: 0;
      left: -10%;
      width: 120%;
      border-color: #FFFFFF;
    }
    .footer-rights-text {
      padding: 1.5rem 0;
    }
  }
}
</style>
